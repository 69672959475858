/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

/* purgecss start ignore */
html,
body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

@screen lg {
  .lg\:w-sm {
    width: 24rem;
  }
}

.big-image-right img,
.big-image-left img {
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
}

.big-image-right,
.basic-image-right {
  order: -1;
}

@screen lg {

  .big-image-right,
  .basic-image-right {
    order: 1;
  }
}

@screen lg {
  .big-image-right {
    margin-right: -300px;
    margin-left: auto;
  }

  .big-image-left {
    margin-right: auto;
    margin-left: -300px;
  }

  .big-image-right img,
  .big-image-left img {
    width: 800px;
    max-width: 800px;
  }
}

@screen xl {
  .big-image-right {
    margin-right: -350px;
    margin-left: auto;
  }

  .big-image-left {
    margin-right: auto;
    margin-left: -350px;
  }

  .big-image-right img,
  .big-image-left img {
    width: 900px;
    max-width: 900px;
  }
}

.embedded-content {
  h1 {
    @apply text-2xl font-bold my-4;
  }

  h2 {
    @apply text-xl font-bold my-4;
  }

  h3 {
    @apply text-lg font-bold my-4;
  }

  h4 {
    @apply font-bold my-4;
  }

  p {
    @apply my-3;
  }

  li {
    @apply list-disc ml-8;
  }

  a {
    @apply text-blue-700 underline;
  }

  a:hover {
    @apply text-blue-500;
  }
}

/* purgecss end ignore */
.-top-10 {
  top: -10px;
}

.-top-20 {
  top: -20px;
}

.-top-40 {
  top: -40px;
}

.boxShadowSpecial {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.min-w-90 {
  min-width: 90%;
}

.m-6 {
  margin: 1.5rem;
}

.pl-5 {
  margin-left: 1.5rem;
}

.border-b {
  border-bottom: 1px;
}

.min-h-image-collapse {
  min-height: 450px;
}

.film:before {
  content: "";
  display: block;
  background-image: url("/images/textures/film2.png");
  width: 100%;
  min-height: 800px;
  height: 100%;
  position: absolute;
  mix-blend-mode: multiply;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.-z-2 {
  z-index: -2;
}

.max-w-1232 {
  max-width: 1232px;
}

.bg-image:before {
  content: "";
  display: block;
  background-image: url("/images/features/indie-memphis-dark.jpg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-opacity: 0.25;
  /* max-width: 1440px; disabled until hero image is styled with fade out */
  @apply mx-auto right-0 left-0 top-0 bottom-0;
}

.bg-image:after {
  content: "";
  display: block;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.live-demo-button {
  background-color: rgba(0, 0, 0, 0.5);
}

.live-demo-button:hover {
  background-color: rgba(240, 82, 82, 0.5);
}

.carouselCard-dimensions {
  width: 150px;
  height: auto;
}

.embla__dots {
  width: 24px;
  height: 3px;
  border-radius: 1px;
  transform: rotate(-180deg);
}

.min-w-max {
  min-width: max-content !important;
}

.mobile-reviews-screen {
  width: 94vw;
}

.font-56 {
  font-size: 56px;
  line-height: 56px;
}

.text-realeaseBar {
  font-size: 19px;
  line-height: 24px;
}

.rotate-center {
  -webkit-animation: rotate-center 0.3s ease-in-out both;
  animation: rotate-center 0.3s ease-in-out both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media (max-width: 767px) {
  .bg-image:before {
    background-size: contain;
  }

  .text-realeaseBar {
    font-size: 16px;
    line-height: 24px;
  }

  .-top-10 {
    top: 0;
  }

  .carouselCard-dimensions {
    width: 100px;
    height: 100px;
  }

  .font-56 {
    font-size: 30px;
    line-height: 32px;
  }
}

.font-28 {
  font-size: 28px;
  line-height: 32px;
}

.text-drop-shadow {
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
}

@media (min-width: 767px) and (max-width: 1283px) {
  .-top-40 {
    top: -15px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .-top-40 {
    top: -23px;
  }
}